import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./MenuAside.module.css";

const links = [
  {
    type: "instrucoes",
    title: "Instruções",
  },
  {
    type: "home",
    title: "Home",
  },
  {
    type: "sobre",
    title: "Sobre",
  },
  {
    type: "brincos",
    title: "Brincos",
  },
  {
    type: "pulseiras",
    title: "Pulseiras",
  },
  {
    type: "colares",
    title: "Colares",
  },
  {
    type: "aneis",
    title: "Anéis",
  },
  {
    type: "pingentes",
    title: "Pingentes",
  },
  {
    type: "conjuntos",
    title: "Conjuntos",
  },
  {
    type: "tornozeleiras",
    title: "Tornozeleiras",
  },
  {
    type: "escapularios",
    title: "Escapulários",
  },
  {
    type: "noivas",
    title: "Noivas",
  },
  {
    type: "promocoes",
    title: "Promoções",
  },
  {
    type: "outros",
    title: "Outros",
  },
  { type: "cadastros", title: "Cadastros" },
];

const MenuAside = ({ setTitle, setType }) => {
  return (
    <aside className={styles.editor_container}>
      <nav>
        <ul className={styles.editor_links}>
          {links.map((link) => {
            return (
              <li key={link.type} className={styles.editor_link}>
                <NavLink
                  to={link.type}
                  id={link.title}
                  onClick={() => {
                    setTitle(link.title);
                    setType(link.type);
                  }}
                >
                  {link.title}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </aside>
  );
};

export default MenuAside;
