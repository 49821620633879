import React from "react";
import styles from "./Input.module.css";

const InputPage = ({
  type,
  id,
  name,
  label,
  place,
  onChange,
  value,
  ...rest
}) => {
  return (
    <label className={`${styles.label}`}>
      {label}
      <input
        className={styles.input}
        type={type}
        id={id}
        name={name}
        placeholder={place}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </label>
  );
};

export default InputPage;
