import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      Ines Sobral e Wanderley Joalharia Ltda. / CNPJ: 30.074.048/0001-05 /
      Endereço: Rua Praça Souto Filho, 21 - Garanhuns, PE - 55.295-400
    </footer>
  );
};

export default Footer;
