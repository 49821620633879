import React from "react";
import styles from "./SignIn.module.css";
import Logo from "../../../svg/logo-v2.svg";
import useAuth from "../../../hooks/useAuth";
import { UserGlobal } from "../../../contexts/userContext";
import { useNavigate } from "react-router-dom";

const SignIn = () => {
  const [userLogin, setUserLogin] = React.useState({ email: "", password: "" });
  const { login, error, loading } = useAuth();
  const { user } = React.useContext(UserGlobal);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user) {
      return;
    } else {
      navigate("/dashboard");
    }
  }, [navigate, user]);

  const handleChange = ({ target }) => {
    setUserLogin({ ...userLogin, [target.id]: target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    login(userLogin.email, userLogin.password);
  };

  return (
    <form className={`${styles.login_form} show`}>
      {loading && (
        <>
          <span className="loading"></span>
          <span className="loading"></span>
          <span className="loading"></span>
        </>
      )}

      <h1>Login</h1>
      <p>Faça o seu login para ter acesso às seções editáveis do site.</p>
      <input
        type="email"
        name="email"
        id="email"
        placeholder="E-mail"
        onChange={handleChange}
      ></input>
      <input
        type="password"
        name="password"
        id="password"
        placeholder="Senha"
        onChange={handleChange}
      ></input>
      {error && <p className="errorIn">E-mail ou senha incorretos</p>}
      <button onClick={handleLogin}>Entrar</button>
      <img src={Logo} alt="Inês Sobral"></img>
    </form>
  );
};

export default SignIn;
