import React from "react";
import { useFirebase } from "../hooks/useFirebase";

export const UserGlobal = React.createContext();

const GlobalUser = ({ children }) => {
  const [user, setUser] = React.useState(null);

  const { onAuthStateChanged, auth } = useFirebase();

  React.useEffect(() => {
    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, [auth, onAuthStateChanged]);

  return (
    <UserGlobal.Provider value={{ user, setUser }}>
      {children}
    </UserGlobal.Provider>
  );
};

export default GlobalUser;
