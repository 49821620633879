import React from "react";
import useFirestoreDocs from "../../hooks/useFirestoreDocs";
import Contact from "../Contact/Contact";
import { Helmet } from "react-helmet";
import styles from "./About.module.css";

const About = () => {
  const { readFirestore } = useFirestoreDocs();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await readFirestore("pages", "page", "==", "sobre");
      setData(() => {
        const dataJSON = fetchedData.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        return dataJSON;
      });
    };
    fetchData();
  }, [readFirestore]);

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https:/inessobral.com.br/sobre" />
        <meta property="og:title" content="Inês Sobral" />
        <meta
          property="og:description"
          content="Conheça um sobre a nossa loja, Inês Sobral"
        />
        <meta property="og:site_name" content="Inês Sobral" />
        <title>Inês Sobral - Sobre</title>
      </Helmet>
      <section className={`${styles.about}`}>
        <div className={`${styles.about_wrapper} grid`}>
          <img
            src={data ? data[0].url : "assets/about-img.jpg"}
            alt="Placeholder"
          ></img>
          <div className={styles.about_wrapper_text}>
            <h1>{!data ? "Sobre a Empresa" : data[0].title}</h1>
            <p>{!data ? "Carregando..." : data[0].description}</p>
          </div>
        </div>
        <Contact />
      </section>
    </>
  );
};

export default About;
