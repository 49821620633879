import React from "react";
import styles from "./Benefits.module.css";
import { ReactComponent as Promo } from "../../svg/home-icon-desconto.svg";
import { ReactComponent as Cartao } from "../../svg/home-icon-cartao.svg";
import { ReactComponent as Frete } from "../../svg/home-icon-frete.svg";
import { ReactComponent as Garantia } from "../../svg/home-icon-garantia.svg";

const Benefits = () => {
  return (
    <section className={styles.benefits}>
      <h1>Uma loja para presentear amigos</h1>
      <ul className={`grid`}>
        <li className={styles.list_item}>
          <Promo />
          <div>
            <p>Descontos em pagamentos</p>
            <p>à vista no PIX, dinheiro ou cartão de débito</p>
          </div>
        </li>
        <li className={styles.list_item}>
          <Cartao />
          <div>
            <p>Parcelamos as suas compras</p>
            <p>Aceitamos todos os cartões de crédito</p>
          </div>
        </li>
        <li className={styles.list_item}>
          <Garantia />
          <div>
            <p>Garantia</p>
            <p>Todos os produtos possuem garantia de até 12 meses</p>
          </div>
        </li>
        <li className={styles.list_item}>
          <Frete />
          <div>
            <p>Frete grátis para Garanhuns-PE</p>
            <p>Negociamos frete para outras cidades</p>
          </div>
        </li>
      </ul>
    </section>
  );
};

export default Benefits;
