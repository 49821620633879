const useCloudinary = () => {
  const uploadImage = async (file) => {
    try {
      const image = file[0];
      const dataImage = new FormData();
      dataImage.append("file", image);
      dataImage.append("upload_preset", "sobral-app");
      const fetchResponse = await fetch(
        "https://api.cloudinary.com/v1_1/inessobral-app/image/upload",
        {
          method: "POST",
          body: dataImage,
        },
      );
      const responseJSON = await fetchResponse.json();
      return responseJSON;
    } catch (error) {
      console.log(error.message);
    }
  };
  return { uploadImage };
};

export default useCloudinary;
