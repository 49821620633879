import React from "react";
import CatalogoHome from "../Catalogo/CatalogoHome";
import Contact from "../Contact/Contact";
import Newsletter from "../Newsletter/Newsletter";
import Slide from "../Slide/Slide";
import Author from "./Author";
import Benefits from "./Benefits";
import Exclusive from "./Exclusive";
import Social from "./Social";
import styles from "./Home.module.css";
import { AnchorContext } from "../../contexts/scrollContext";

const Home = () => {
  const { section, setSection } = React.useContext(AnchorContext);

  React.useEffect(() => {
    if (section != null) {
      const el = document.querySelector(section);
      el.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
    setSection(null);
  }, [section, setSection]);

  const handleUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Slide path={"assets"} />
      <Newsletter id="discount" />
      <Benefits />
      <Author />
      <CatalogoHome id="catalogo" />
      <Exclusive />
      <Social />
      <Contact id="contato" />
      <button className={styles.buttonUp} onClick={handleUp}>
        <p>Retornar ao topo</p>
      </button>
    </>
  );
};

export default Home;
