import React from "react";

export const AnchorContext = React.createContext();

const GlobalAnchor = ({ children }) => {
  const [section, setSection] = React.useState(null);

  function handleAnchor({ target }) {
    setSection(target.hash);
  }

  return (
    <AnchorContext.Provider value={{ handleAnchor, section, setSection }}>
      {children}
    </AnchorContext.Provider>
  );
};

export default GlobalAnchor;
