import React from "react";
import styles from "./MediaLink.module.css";

const MediaLink = ({ href, src, children }) => {
  return (
    <a
      href={href}
      rel="noreferrer"
      target="_blank"
      className={styles.media_link}
    >
      <img src={src} alt="Inês Sobral"></img>
      {children}
    </a>
  );
};

export default MediaLink;
