import React from "react";
import styles from "./ItemCatalogoHome.module.css";
import { Link } from "react-router-dom";

const ItemCatalogoHome = ({ title, type, src, alt, url }) => {
  return (
    <li className={styles.catalogo_item}>
      <h2>{type}</h2>
      <Link to={`catalogo/${url}`}>
        <img src={src} alt={alt}></img>
      </Link>
      <Link to={`catalogo/${url}`} className={styles.catalogo_item_link}>
        <p>{title}</p>
      </Link>
    </li>
  );
};

export default ItemCatalogoHome;
