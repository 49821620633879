import React from "react";
import styles from "./Filter.module.css";

const Filter = ({ options, setQueryType }) => {
  const [filter, setFilter] = React.useState("");
  const handleChange = ({ target }) => {
    setFilter(target.value);
    setQueryType(target.value);
  };

  return (
    <div className={styles.filter}>
      <select type="select" value={filter} onChange={handleChange}>
        <option value="" disabled>
          Selecione
        </option>
        {options.map((option) => {
          return (
            <option value={option} key={option}>
              {option}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Filter;
