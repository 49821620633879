import React from "react";
import SocialMedias from "../SocialMedias/SocialMedias";
import styles from "./Address.module.css";

const Address = ({ tel, b }) => {
  return (
    <div className={styles.address}>
      <div className={styles.address_block}>
        <h1>Contato e endereço</h1>
        <p>
          <span className="bold">Telefone e WhatsApp:</span> (87) 9.9610-8283
        </p>
        <p>
          <span className="bold">Email:</span> contato@inessobral.com.br
        </p>
      </div>
      <div className={styles.address_block}>
        <p>
          <span className="bold">Bairro:</span> Heliópolis
        </p>
        <p>
          <span className="bold">Rua:</span> Praça Souto Filho (fonte luminosa)
        </p>
        <p>
          <span className="bold">Número:</span> 21
        </p>
        <p>
          <span className="bold">Cep:</span> 55.295-400
        </p>
        <p>Guaranhuns - PE</p>
      </div>
      <div className={styles.address_block}>
        <a
          href="https://goo.gl/maps/zR7P3qetmnULTVGi9"
          target="_blank"
          rel="noreferrer"
        >
          Clique aqui para ir à loja física
        </a>
      </div>
      <div className={styles.address_block}>
        <p>
          <span className="bold">Horário de funcionamento:</span>
        </p>
        <p>Segunda a Sexta-feira: das 8 às 18h</p>
        <p>Sábado: das 8 às 14h</p>
        <p className="underline">Não fechamos para almoço</p>
      </div>
      <SocialMedias />
    </div>
  );
};

export default Address;
