import React from "react";
import { useNavigate } from "react-router-dom";
import { useFirebase } from "./useFirebase";

const useAuth = () => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const {
    createUserWithEmailAndPassword,
    auth,
    signInWithEmailAndPassword,
    signOut,
  } = useFirebase();
  const navigate = useNavigate();

  const register = async (login, email) => {
    try {
      await createUserWithEmailAndPassword(auth, login, email);
    } catch (error) {
      console.log(error);
    }
  };

  const login = async (userLogin, userPassword) => {
    try {
      setError(false);
      setLoading(true);
      await signInWithEmailAndPassword(auth, userLogin, userPassword);
    } catch (error) {
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    await signOut(auth);
    navigate("/");
  };

  return {
    register,
    login,
    logout,
    auth,
    error,
    loading,
  };
};

export default useAuth;
