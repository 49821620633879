import React from "react";
import { Helmet } from "react-helmet";
import MenuAside from "./MenuAside/MenuAside";
import Logo from "../../svg/logo-v2.svg";
import styles from "./Dashboard.module.css";
import CompHome from "./Editor/CompHome";
import CompAbout from "./Editor/CompAbout";
import CompInst from "./Editor/CompInst";
import Editor from "./Editor/Editor";
import { UserGlobal } from "../../contexts/userContext";
import { Route, Routes, useNavigate } from "react-router-dom";
import Cadastro from "./Cadastro/Cadastro";

const Dashboard = () => {
  const [title, setTitle] = React.useState(null);
  const [type, setType] = React.useState(null);
  const { user } = React.useContext(UserGlobal);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  return (
    <>
      <Helmet>
        <meta property="og:url" content="https:/inessobral.com.br/dashboard" />
        <meta property="og:title" content="Painel" />
        <meta property="og:description" content="Painel de administrador" />
        <meta property="og:site_name" content="Painel" />
        <meta name="theme-color" content="#373737" />
        <title>Painel</title>
      </Helmet>
      <section className={`${styles.container} grid`}>
        <img className={styles.logo} src={Logo} alt="Inês Sobral"></img>
        <div className={styles.dash}>
          <MenuAside {...{ setTitle, setType }} />
          <Routes>
            <Route path="/" index element={<CompInst />}></Route>
            <Route path="instrucoes" element={<CompInst />}></Route>
            <Route path="home" element={<CompHome />}></Route>
            <Route path="sobre" element={<CompAbout />}></Route>
            <Route path="cadastros" element={<Cadastro />}></Route>
            <Route path="*" element={<Editor {...{ title, type }} />}></Route>
          </Routes>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
