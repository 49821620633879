import React from "react";
import useFirestoreDocs from "../../hooks/useFirestoreDocs";
import styles from "./Author.module.css";

const Author = () => {
  const { readFirestore } = useFirestoreDocs();
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const fetchedData = await readFirestore("pages", "page", "==", "home");

      setData(() => {
        const dataJSON = fetchedData.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        return dataJSON;
      });
    };
    fetchData();
  }, [readFirestore]);

  return (
    <section className={styles.author}>
      <div className={`${styles.author_section} grid`}>
        <div>
          <h1>{data ? data[0].title : "Carregando"}</h1>
          <p>{data ? data[0].description : "..."}</p>
        </div>
        <img
          src={data ? data[0].url : "assets/about-img.jpg"}
          alt="Change"
        ></img>
      </div>
    </section>
  );
};

export default Author;
