import React from "react";
import StandardHeader from "./StandardHeader";
import UserHeader from "./UserHeader";
import { UserGlobal } from "../../contexts/userContext";

const Header = () => {
  const { user } = React.useContext(UserGlobal);

  if (user) {
    return <UserHeader />;
  } else {
    return <StandardHeader />;
  }
};

export default Header;
