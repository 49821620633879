import React from "react";
import Address from "./Address";
import ContactPics from "./ContactPics";
import { ReactComponent as Logo } from "../../svg/logo-v2.svg";
import styles from "./Contact.module.css";

const Contact = ({ id }) => {
  return (
    <section id={id} className={styles.contact}>
      <div className={`${styles.contact_content} grid`}>
        <Address className={styles.contact_address} />
        <ContactPics className={styles.contact_pics} />
      </div>
      <div className={`grid`}>
        <Logo />
      </div>
    </section>
  );
};

export default Contact;
