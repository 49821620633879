import React from "react";
import styles from "./Input.module.css";

const Checkbox = ({
  options,
  children,
  categories,
  setCategories,
  ...rest
}) => {
  const handleCheckbox = ({ target }) => {
    if (target.checked) {
      setCategories([...categories, target.value]);
    } else {
      setCategories(categories.filter((category) => category !== target.value));
    }
  };

  return (
    <>
      <div className={styles.checkbox_env}>
        {options.map((option) => {
          return (
            <label key={option}>
              <input
                className={styles.checkbox}
                type="checkbox"
                onChange={handleCheckbox}
                value={option}
                checked={categories.includes(option)}
                {...rest}
              />
              {option}
            </label>
          );
        })}
      </div>
    </>
  );
};

export default Checkbox;
