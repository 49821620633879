import React from "react";
import styles from "./TextArea.module.css";

const TextArea = ({
  place,
  rows,
  cols,
  label,
  onChange,
  value,
  id,
  ...rest
}) => {
  return (
    <label className={styles.label}>
      {label}
      <textarea
        id={id}
        className={styles.textarea}
        rows={rows}
        cols={cols}
        placeholder={place}
        onChange={onChange}
        value={value}
        {...rest}
      ></textarea>
    </label>
  );
};

export default TextArea;
