import React from "react";
import useFirestoreDocs from "../../../../hooks/useFirestoreDocs";
import useCloudinary from "../../../../hooks/useCloudinary";
import InputPage from "./Inputs/InputPage";
import TextArea from "./Inputs/TextArea";
import styles from "./PageForm.module.css";
import { useParams } from "react-router-dom";

const PageForm = () => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [fields, setFields] = React.useState({
    title: "",
    description: "",
    page: "",
  });
  const params = useParams();
  const { readFirestore, setFirestoreDoc } = useFirestoreDocs();
  const { uploadImage } = useCloudinary();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setError(false);
        setLoading(true);
        const querySnapshot = await readFirestore(
          "pages",
          "page",
          "==",
          params["*"],
        );
        setFields({ ...querySnapshot.docs[0].data() });
      } catch (error) {
        setError(true);
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [params, readFirestore]);

  const handleChange = ({ target }) => {
    setFields({ ...fields, [target.id]: target.value });
  };

  const handleImage = ({ target }) => {
    setImage(target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (!image) {
        await setFirestoreDoc("pages", `${params["*"]}id`, fields);
      } else {
        const Link = await uploadImage(image);
        const formatFields = {
          title: fields.title,
          description: fields.description,
          page: params["*"],
          url: Link.url,
        };
        await setFirestoreDoc("pages", `${params["*"]}id`, formatFields);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles.page_form}>
      <InputPage
        type="file"
        id="archive"
        name="archive"
        label={"Selecione uma imagem"}
        onChange={handleImage}
      />
      <InputPage
        type="text"
        id="title"
        name="title"
        label="Título:"
        place={"Escreva o Título"}
        value={fields.title}
        onChange={handleChange}
      />
      <TextArea
        id="description"
        place={"Coloque a nova descrição aqui."}
        rows={10}
        cols={60}
        label={"Descrição:"}
        value={fields.description}
        onChange={handleChange}
      />
      <button className={styles.submit_button} onClick={handleSubmit}>
        {loading ? "Salvando" : "Salvar"}
      </button>
      {error && <p className="show">Houve um erro, tente criar novamente.</p>}
    </form>
  );
};

export default PageForm;
