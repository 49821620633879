import React from "react";
import Input from "./Inputs/Input";
import styles from "./Create.module.css";
import Checkbox from "./Inputs/Checkbox";
import useFirestoreDocs from "../../../../hooks/useFirestoreDocs";
import { useParams } from "react-router-dom";
import useCloudinary from "../../../../hooks/useCloudinary";
import InputPage from "./Inputs/InputPage";

const tags = {
  brincos: [
    "Argolas",
    "Brincos",
    "Ear Cuff",
    "Lançamento",
    "Piercings",
    "Masculino",
    "Prata",
    "Infantil",
    "Folheado",
    "Feminino",
  ],
  pulseiras: [
    "Bracelete",
    "Feminino",
    "Folheado",
    "Infantil",
    "Lançamento",
    "Masculino",
    "Prata",
    "Pulseira",
  ],
  colares: [
    "Chokers",
    "Colares",
    "Feminino",
    "Folheado",
    "Masculino",
    "Prata",
    "Lançamento",
  ],
  aneis: [
    "Aparadores",
    "Falange",
    "Feminino",
    "Folheado",
    "Masculino",
    "Solitário",

    "Lançamento",
  ],
  pingentes: [
    "Feminino",
    "Folheado",
    "Infantil",
    "Masculino",
    "Prata",
    "Lançamento",
  ],
  conjuntos: [
    "Adulto",
    "Folheado",
    "Inafntil",
    "Masculino",
    "Prata",
    "Lançamento",
  ],
  tornozeleiras: ["Feminino", "Infantil", "Masculino", "Prata", "Lançamento"],
  escapularios: [
    "Feminino",
    "Folheado",
    "Infantil",
    "Masculino",
    "Prata",
    "Lançamento",
  ],
  noivas: ["Brincos", "Folheado", "Prata", "Terços", "Tiara", "Lançamento"],
  promocoes: ["Maior preço", "Menor preço", "Lançamento"],
  outros: ["Lançamento"],
};

const Create = () => {
  const { addFirestore } = useFirestoreDocs();
  const { uploadImage } = useCloudinary();
  const [loading, setLoading] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [url, setUrl] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [fields, setFields] = React.useState({
    name: "",
    pid: "",
    promo_price: "",
    price: "",
    stock: "",
  });

  const params = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formatFields = {
        name: fields.name,
        pid: Number(fields.pid),
        promo_price: Number(fields.promo_price),
        price: Number(fields.price),
        stock: Number(fields.stock),
        categories,
        url,
      };
      await addFirestore(params.id, formatFields);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImage = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const r = await uploadImage(image);
      setUrl(r.url);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setFields({ name: "", pid: "", price: "", stock: "" });
    }
  };

  const handleChange = (e) => {
    setImage(e.target.files);
  };

  return (
    <form className={`${styles.create_form} show`}>
      <InputPage
        type="file"
        id="file"
        name="file"
        label="Imagem do produto:"
        onChange={handleChange}
      />
      <button
        className="submit_button"
        disabled={loading}
        onClick={handleImage}
      >
        Salvar Imagem
      </button>
      <Input
        type="text"
        id="name"
        name="name"
        label="Nome do produto:"
        place={`Exemplo: ''Produto de prata - 5949''`}
        value={fields.name}
        setValue={setFields}
        fields={fields}
      />
      <Input
        type="text"
        id="pid"
        name="pid"
        label="Código do Produto:"
        place='Exemplo: "123456"'
        value={fields.pid}
        setValue={setFields}
        fields={fields}
      />
      <Input
        type="text"
        id="price"
        name="price"
        label="Preço do Produto:"
        place='Exemplo: "158.00"'
        value={fields.price}
        setValue={setFields}
        fields={fields}
      >
        <p className={styles.label_text}>
          Coloque <span className="bold">apenas o valor númerico do preço</span>{" "}
          e utilize ponto (.) para separar os centavos. Conforme o exemplo.
        </p>
      </Input>
      <Input
        type="text"
        id="promo_price"
        name="promo_price"
        label="Preço com desconto"
        place='Exemplo: "99.9"'
        value={fields.promo_price}
        setValue={setFields}
        fields={fields}
      />
      <Input
        type="text"
        id="stock"
        name="stock"
        label="Unidades disponíveis:"
        value={fields.stock}
        setValue={setFields}
        fields={fields}
      >
        <p className={styles.label_text}>Apenas o valor númerico.</p>
      </Input>
      <p className={styles.checkbox_text}>
        <span className="bold">Categorias do produto:</span> (selecione todas as
        categorias em que o produto em questão está enquadrado):
      </p>

      <Checkbox
        options={tags[params["id"]]}
        id="categories"
        name="categories"
        categories={categories}
        setCategories={setCategories}
      ></Checkbox>

      <button
        onClick={handleSubmit}
        className={`${styles.submit} submit_button`}
        disabled={loading}
      >
        {loading ? "Criando" : "Criar produto"}
      </button>
    </form>
  );
};

export default Create;
