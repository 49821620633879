import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import useFirestoreDocs from "../../../../hooks/useFirestoreDocs";
import styles from "./Editar.module.css";

const Editar = ({ productID, setProductID }) => {
  const { readFirestoreAll, deleteFirestoreDoc } = useFirestoreDocs();
  const [data, setData] = React.useState(null);
  const [limit, setLimit] = React.useState(5);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const params = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const getData = async (ref, orderType) => {
      try {
        const r = await readFirestoreAll(ref, limit, orderType);
        if (r.empty) {
          setData(null);
        } else {
          setData(r);
        }
      } catch (error) {
        console.log(error.message());
      }
    };
    getData(params.id, ["name", "asc"]);
  }, [limit, params.id, readFirestoreAll]);

  const handleEdit = ({ target }) => {
    setProductID(target.value);
    navigate(target.id);
  };

  const handleDelete = async ({ target }) => {
    try {
      setError(null);
      setLoading(true);
      const queryResponse = await deleteFirestoreDoc(params.id, target.id);
      console.log(queryResponse);
    } catch (error) {
      setError(true);
      console.log(error.message);
    } finally {
      setLoading(false);
      setError(null);
    }
  };

  if (!data) {
    return (
      <h1 style={{ marginTop: "8rem", fontSize: "3rem" }} className="show">
        {error
          ? "Houve um erro, recarregue a página."
          : "Produtos não encontrados"}
      </h1>
    );
  }
  return (
    <section className={styles.editar}>
      {data.docs.map((doc) => {
        const produto = doc.data();
        return (
          <div className={`${styles.produto} show`} key={doc.id}>
            <p className={styles.title}>{produto.name}</p>
            <div className={styles.buttons}>
              <button
                className={`submit_button`}
                value={doc.id}
                id={produto.name}
                onClick={handleEdit}
              >
                Editar produto
              </button>
              <button
                className={`${styles.red} submit_button`}
                value={doc.id}
                id={doc.id}
                onClick={handleDelete}
              >
                {loading ? "Apagando" : "Apagar produto"}
              </button>
            </div>
            {error && "Houve um erro, tente novamente."}
          </div>
        );
      })}
      <button
        className="submit_button show"
        onClick={() => {
          let limitRef = limit;
          setLimit(limitRef + 10);
        }}
        style={{
          marginTop: "5rem",
          fontWeight: 400,
        }}
      >
        Mais Produtos
      </button>
    </section>
  );
};

export default Editar;
