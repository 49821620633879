import React from "react";
import styles from "./CatalogoHome.module.css";
import ItemCatalogo from "./ItemCatalogoHome";

const CatalogoHome = ({ id }) => {
  const products = [
    {
      title: "Catálogo de Brincos",
      type: "brincos",
      url: "brincos",
      src: "assets/home-card-brincos.jpg",
    },
    {
      title: "Catálogo de Pulseiras",
      type: "pulseiras",
      url: "pulseiras",
      src: "assets/home-card-pulseira.jpg",
    },
    {
      title: "Catálogo de Colares",
      type: "colares",
      url: "colares",
      src: "assets/home-card-colares.jpg",
    },
    {
      title: "Catálogo de Anéis",
      type: "anéis",
      url: "aneis",
      src: "assets/home-card-aneis.jpg",
    },
    {
      title: "Catálogo de Pingentes",
      type: "pingentes",
      url: "pingentes",
      src: "assets/home-card-pingentes.jpg",
    },
    {
      title: "Catálogo de Conjuntos",
      type: "conjuntos",
      url: "conjuntos",
      src: "assets/home-card-conjuntos.jpg",
    },
    {
      title: "Catálogo de Tornozeleiras",
      type: "tornozeleiras",
      url: "tornozeleiras",
      src: "assets/home-card-tornozeleiras.jpg",
    },
    {
      title: "Catálogo de Escapulários",
      type: "escapulários",
      url: "escapularios",
      src: "assets/home-card-escapularios.jpg",
    },
    {
      title: "Catálogo de Noivas",
      type: "noivas",
      url: "noivas",
      src: "assets/home-card-noivas.jpg",
    },
    {
      title: "Confira nossas promoções",
      type: "promoções",
      url: "promocoes",
      src: "assets/home-card-promocoes.jpg",
    },
    {
      title: "Catálogo de outras peças",
      type: "outros",
      url: "outros",
      src: "assets/home-card-outros.jpg",
    },
  ];

  return (
    <section className={styles.catalogo} id={id}>
      <h1>Que tipo de peça você está buscando?</h1>
      <p>
        Clique nos botões abaixo para ser direcionado para o catálogo de peças
        que você deseja.
      </p>
      <ul className={`${styles.catalogo_lista} grid`}>
        {products.map((product) => {
          return (
            <ItemCatalogo
              key={product.type}
              title={product.title}
              type={product.type}
              url={product.url}
              src={product.src}
              alt={`Fotografia ${product.type}`}
            />
          );
        })}
      </ul>
    </section>
  );
};

export default CatalogoHome;
