import React from "react";
import styles from "./ContactPics.module.css";

const ContactPics = () => {
  const pics = [
    { src: "assets/home-footer-01.jpg", key: 1 },
    { src: "assets/home-footer-02.jpg", key: 2 },
    { src: "assets/home-footer-03.jpg", key: 3 },
    { src: "assets/home-footer-04.jpg", key: 4 },
  ];

  return (
    <ul className={styles.contact_pics}>
      {pics.map((pic) => {
        return (
          <img
            key={pic.key}
            src={pic.src}
            alt="Fotagrafia Estabelecimento"
          ></img>
        );
      })}
    </ul>
  );
};

export default ContactPics;
