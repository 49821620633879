import React from "react";
import { Route, Routes } from "react-router-dom";
import styles from "./Login.module.css";
import SignIn from "./SignIn/SignIn";
import CreateUser from "./CreateUser/CreateUser";

const Login = () => {
  return (
    <section className={styles.login}>
      <div className="grid">
        <Routes>
          <Route path="/" element={<SignIn />} end></Route>
          <Route path="registrar" element={<CreateUser />}></Route>
        </Routes>
      </div>
    </section>
  );
};

export default Login;
