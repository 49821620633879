import React from "react";
import styles from "./Input.module.css";

const Input = ({
  type,
  id,
  name,
  label,
  place,
  children,
  value,
  setValue,
  fields,
}) => {
  const handleChange = ({ target }) => {
    setValue({ ...fields, [target.name]: target.value });
  };

  return (
    <>
      <label htmlFor={name} className={styles.label}>
        {label}
        {children}
      </label>
      <input
        className={styles.input}
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={place}
      />
    </>
  );
};

export default Input;
