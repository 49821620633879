import React from "react";
import styles from "./Social.module.css";

const Social = () => {
  return (
    <section className={`${styles.social} grid`}>
      <h1>Siga a nossa loja no instagram</h1>
      <p>E fique por dentro de todas as novidades!</p>
      <a
        href="https://www.instagram.com/inessobralsemijoias/"
        target="_blank"
        rel="noreferrer"
      >
        @inessobralsemijoias
      </a>
      <ul className={styles.social_pics}>
        <li>
          <img src="assets/home-instagram-01.jpg" alt="Modelo 1"></img>
        </li>
        <li>
          <img src="assets/home-instagram-02.jpg" alt="Modelo 2"></img>
        </li>
        <li>
          <img src="assets/home-instagram-03.jpg" alt="Modelo 3"></img>
        </li>
        <li>
          <img src="assets/home-instagram-04.jpg" alt="Modelo 4"></img>
        </li>
      </ul>
    </section>
  );
};

export default Social;
