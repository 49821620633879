import React from "react";
import PageForm from "./Forms/PageForm";
import styles from "./Comp.module.css";

const CompHome = () => {
  return (
    <section className={`${styles.component} show`}>
      <h1>Home</h1>
      <div className={styles.wrapper_form}>
        <PageForm />
      </div>
    </section>
  );
};

export default CompHome;
