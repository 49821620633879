import React from "react";
import styles from "./Newsletter.module.css";
import Form from "./Form";

const Newsletter = ({ id }) => {
  return (
    <section id={id} className={styles.newsletter_section}>
      <div className={`${styles.newsletter} grid`}>
        <div className={styles.newsletter_text}>
          <h1 className={styles.newsletter_h1}>
            Cadastre-se e receba 10% de desconto!
          </h1>
          <p>Promoção válida apenas para primeira compra.</p>
          <p>Desconto não cumulativo!</p>
        </div>
        <Form />
      </div>
    </section>
  );
};

export default Newsletter;
