import React, { useRef } from "react";
import Dropdown from "./Dropdown";
import styles from "./Menu.module.css";

const Menu = () => {
  const [active, setActive] = React.useState(false);
  const refMenu = React.useRef();

  const handleWindow = (e) => {
    if (!e.target.closest(`.${refMenu.current.className}`)) {
      setActive(false);
    }
  };

  const handleMenu = (e) => {
    setActive(!active);
  };

  React.useEffect(() => {
    document.addEventListener("click", handleWindow);
    return () => {
      document.removeEventListener("click", handleWindow);
    };
  });

  return (
    <nav>
      <div className={styles.navMenu} onClick={handleMenu} ref={refMenu}>
        Menu
      </div>
      {active && <Dropdown active={active} setActive={setActive} />}
    </nav>
  );
};

export default Menu;
