import React from "react";
import styles from "./CompInst.module.css";

const CompInst = () => {
  return (
    <section className={`${styles.instrucoes} show`}>
      <h1>Instruções básicas</h1>
      <p>
        Esta é a sua área de acesso às seções editáveis do site da loja Inês
        Sobral.
      </p>
      <p>
        Através desta plataforma, você pode selecionar os campos que deseja
        editar no painel à esquerda e preencher os quadrantes que aparecerem,
        prestando sempre atenção no
        <span className="underline"> limite de caracteres</span> de cada seção,
        bem como na <span className="underline">resolução e no tamanho</span> de
        cada imagem - nos locais em que há esta opção.
      </p>
      <p>
        Após finalizar as edições e atualizações necessárias, não esqueça de
        clicar em: <span className="bold">"Salvar e publicar"</span> logo abaixo
        do item que você acabou de editar.
      </p>
      <p>Em alguns minutos a atualização feita será publicada para todos.</p>
    </section>
  );
};

export default CompInst;
