import React from "react";
import styles from "./UserHeader.module.css";
import Logo from "../../svg/logo-v2.svg";
import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const UserHeader = () => {
  const { logout } = useAuth();

  return (
    <header className={styles.header_user}>
      <div className="grid">
        <Link to="/" className={styles.logo}>
          <img src={Logo} alt="Ines Sobral"></img>
        </Link>
        <div className={styles.painel}>
          <Link to="dashboard/instrucoes" className={styles.link}>
            Painel
          </Link>
          <button onClick={logout}>Sair</button>
        </div>
      </div>
    </header>
  );
};

export default UserHeader;
