import React from "react";
import styles from "./Input.module.css";
import useNewsletter from "../../hooks/useNewsletter";

const Input = ({ type, id, name, placeholder, ...props }) => {
  const { error, handleBlur } = useNewsletter();

  return (
    <>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        {...props}
        className={styles.newsInput}
        onBlur={handleBlur}
      ></input>
      {error && <p className={styles.error}>Ops! Preencha o dado.</p>}
    </>
  );
};

export default Input;
