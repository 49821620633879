import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import ProdutoSpec from "./components/Produto/ProdutoSpec";
import Dashboard from "./components/Dashboard/Dashboard";
import Footer from "./components/Footer/Footer";
import Login from "./components/Login/Login";
import GlobalAnchor from "./contexts/scrollContext";
import GlobalUser from "./contexts/userContext";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <BrowserRouter>
        <Helmet>
          <meta property="og:url" content="https:/inessobral.com.br" />
          <meta property="og:title" content="Inês Sobral" />
          <meta
            property="og:description"
            content="Joalheria, bijuteria para amigos e parentes."
          />
          <meta property="og:site_name" content="Inês Sobral - Loja" />
          <meta
            property="og:image"
            content="https://inessobral.com.br/assets/preview.jpg"
          />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="pt_BR" />
          <meta name="theme-color" content="#373737" />
          <title>Inês Sobral</title>
        </Helmet>
        <GlobalUser>
          <GlobalAnchor>
            <Header />
            <Routes>
              <Route exact path="/" element={<Home />}></Route>
              <Route path="sobre" element={<About />}></Route>
              <Route path="catalogo/:id" element={<ProdutoSpec />}></Route>
              <Route path="login/*" element={<Login />}></Route>
              <Route path="dashboard/*" element={<Dashboard />}></Route>
            </Routes>
            <Footer />
          </GlobalAnchor>
        </GlobalUser>
      </BrowserRouter>
    </>
  );
}

export default App;
