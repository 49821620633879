import {
  collection,
  addDoc,
  getDocs,
  query,
  limit,
  where,
  orderBy,
  setDoc,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import { db, app } from "../firebase-config";
import {
  getAuth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";

const auth = getAuth(app);

export const useFirebase = () => {
  return {
    db,
    auth,
    collection,
    addDoc,
    setDoc,
    doc,
    getDoc,
    getDocs,
    app,
    deleteDoc,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
    query,
    where,
    limit,
    orderBy,
  };
};
