import React from "react";
import { useParams } from "react-router-dom";
import useFirestoreDocs from "../../../../hooks/useFirestoreDocs";
import Input from "./Inputs/Input";
import Checkbox from "./Inputs/Checkbox";
import styles from "./Create.module.css";
import InputPage from "./Inputs/InputPage";
import useCloudinary from "../../../../hooks/useCloudinary";

const ProductEditor = ({ productID }) => {
  const [product, setProduct] = React.useState(null);
  const { uploadImage } = useCloudinary();
  const [image, setImage] = React.useState(null);
  const [url, setUrl] = React.useState(null);
  const [categories, setCategories] = React.useState([]);
  const [fields, setFields] = React.useState({
    name: "",
    pid: "",
    price: "",
    stock: "",
    url: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [save, setSave] = React.useState(false);

  const { readFirestoreDoc, setFirestoreDoc } = useFirestoreDocs();
  const params = useParams();

  React.useEffect(() => {
    const getData = async (collectionRef, id) => {
      const productData = await readFirestoreDoc(collectionRef, id);
      setProduct(productData.data());
      setFields({ ...productData.data() });
      setCategories([...productData.data().categories]);
    };
    getData(params.id, productID);
    setSave(false);
  }, [params.id, readFirestoreDoc, save]);

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      const formatFields = {
        name: fields.name,
        pid: Number(fields.pid),
        price: Number(fields.price),
        stock: Number(fields.stock),
        categories,
        url,
      };
      await setFirestoreDoc(params.id, productID, formatFields);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
      setSave(true);
    }
  };

  const handleImage = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const r = await uploadImage(image);
      setUrl(r.url);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setImage(e.target.files);
  };

  if (!product) {
    return null;
  }
  return (
    <form className={`${styles.create_form} show`}>
      {!fields.url ? null : (
        <img className={styles.image_edit} src={fields.url} alt="Produto"></img>
      )}
      <InputPage
        type="file"
        id="file"
        name="file"
        label="Imagem do produto:"
        onChange={handleChange}
      />
      <button
        className="submit_button"
        disabled={loading}
        onClick={handleImage}
      >
        Salvar Imagem
      </button>
      <Input
        type="text"
        id="name"
        name="name"
        label="Nome:"
        value={fields.name}
        setValue={setFields}
        fields={fields}
      />
      <Input
        type="text"
        id="pid"
        name="pid"
        label="Código do Produto:"
        value={fields.pid}
        setValue={setFields}
        fields={fields}
      />
      <Input
        type="text"
        id="price"
        name="price"
        label="Preço do produto:"
        value={fields.price}
        setValue={setFields}
        fields={fields}
      />
      <Input
        type="text"
        id="stock"
        name="stock"
        label="Estoque disponível:"
        value={fields.stock}
        setValue={setFields}
        fields={fields}
      />
      <Checkbox
        options={[
          "Argolas",
          "Folheado",
          "Piercings",
          "Brincos",
          "Infantil",
          "Prata",
          "Ear Cuff",
          "Lançamento",
          "Feminino",
          "Masculinos",
        ]}
        id="categories"
        name="categories"
        categories={categories}
        setCategories={setCategories}
      ></Checkbox>
      <button
        className="submit_button"
        disabled={loading}
        onClick={handleSubmit}
      >
        {loading ? "Salvando" : "Editar Produto"}
      </button>
    </form>
  );
};

export default ProductEditor;
