import React from "react";
import styles from "./Editor.module.css";
import { Link, Route, Routes } from "react-router-dom";
import Create from "./Forms/Create";
import Editar from "./Forms/Editar";
import ProductEditor from "./Forms/ProductEditor";

const Editor = ({ title, type }) => {
  const [productID, setProductID] = React.useState(null);
  return (
    <section className={styles.editor}>
      <h1>Catálogo de {title}</h1>
      <Link to={`${type}/criar`} className={styles.link}>
        Criar
      </Link>
      <Link to={`${type}/editar`} className={styles.link}>
        Editar
      </Link>
      <Routes>
        <Route path=":id" index element={<Create />}></Route>
        <Route path=":id/criar" element={<Create />} />
        <Route
          path=":id/editar/*"
          element={<Editar productID={productID} setProductID={setProductID} />}
        />
        <Route
          path=":id/editar/:product"
          element={<ProductEditor productID={productID} />}
        ></Route>
      </Routes>
    </section>
  );
};

export default Editor;
