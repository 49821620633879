import React from "react";
import styles from "./SocialMedia.module.css";
import MediaLink from "./MediaLink";

const SocialMedias = () => {
  return (
    <ul className={styles.social_media}>
      <MediaLink
        href="https://messenger.com/t/mariaines.espindola"
        src="assets/home-icon-messenger.svg"
      >
        <p>
          messenger.com/t/<span className="bold">mariaines.espindola</span>
        </p>
      </MediaLink>
      <MediaLink
        href="https://instagram.com/inessobralsemijoias"
        src="assets/home-icon-insta.svg"
      >
        <p>
          instagram.com/<span className="bold">inessobralsemijoias</span>
        </p>
      </MediaLink>
      <MediaLink
        href="https://wa.me/+5587996108283"
        src="assets/home-icon-whatsapp.svg"
      >
        <p>
          <span className="bold">(87) 9.9610-8283</span>
        </p>
      </MediaLink>
    </ul>
  );
};

export default SocialMedias;
