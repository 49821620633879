import React from "react";
import { useParams } from "react-router-dom";
import { useFirebase } from "../../hooks/useFirebase";
import { Helmet } from "react-helmet";
import Filter from "./Filter";
import styles from "./ProdutoSpec.module.css";
import { ReactComponent as Whats } from "../../svg/home-icon-whatsapp.svg";
import { ReactComponent as Watch } from "../../svg/icon-watch.svg";
import { ReactComponent as Noiva } from "../../svg/icon-noivas.svg";
import { ReactComponent as Promo } from "../../svg/icon-promo.svg";

const tags = {
  brincos: [
    "Argolas",
    "Brincos",
    "Ear Cuff",
    "Piercings",
    "Masculino",
    "Prata",
    "Infantil",
    "Folheado",
    "Feminino",
    "Lançamento",
    "Maior preço",
    "Menor preço",
  ],
  pulseiras: [
    "Bracelete",
    "Feminino",
    "Folheado",
    "Infantil",
    "Masculino",
    "Prata",
    "Pulseira",
    "Lançamento",
    "Maior preço",
    "Menor preço",
  ],
  colares: [
    "Chokers",
    "Colares",
    "Feminino",
    "Folheado",
    "Masculino",
    "Prata",
    "Lançamento",
    "Maior preço",
    "Menor preço",
  ],
  aneis: [
    "Aparadores",
    "Falange",
    "Feminino",
    "Folheado",
    "Masculino",
    "Solitário",
    "Lançamento",
    "Maior preço",
    "Menor preço",
  ],
  pingentes: ["Feminino", "Folheado", "Infantil", "Masculino", "Prata", "Lançamento", "Maior preço", "Menor preço"],
  conjuntos: ["Adulto", "Folheado", "Inafntil", "Masculino", "Prata", "Lançamento", "Maior preço", "Menor preço"],
  tornozeleiras: ["Feminino", "Infantil", "Masculino", "Prata", "Lançamento", "Maior preço", "Menor preço"],
  escapularios: ["Feminino", "Folheado", "Infantil", "Masculino", "Prata", "Lançamento", "Maior preço", "Menor preço"],
  noivas: ["Brincos", "Folheado", "Prata", "Terços", "Tiara", "Lançamento", "Maior preço", "Menor preço"],
  promocoes: ["Lançamento", "Maior preço", "Menor preço"],
  outros: ["Lançamento", "Maior preço", "Menor preço"],
};

const prices = ["Maior preço", "Menor preço"];

const ProdutoSpec = () => {
  const [products, setProducts] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState(null);
  const [limitRef, setLimitRef] = React.useState(16);
  const [queryType, setQueryType] = React.useState("Menor preço");

  const params = useParams();

  const { db, collection, getDocs, query, where, orderBy, limit } = useFirebase();

  const arrayTypes = [
    "Argolas",
    "Brincos",
    "Ear Cuff",
    "Lançamento",
    "Piercings",
    "Masculinos",
    "Infantil",
    "Folheado",
    "Feminino",
  ];

  React.useEffect(() => {
    switch (params.id) {
      case "brincos":
        setTitle("brincos");
        break;
      case "pulseiras":
        setTitle("pulseiras");
        break;
      case "colares":
        setTitle("colares");
        break;
      case "aneis":
        setTitle("anéis");
        break;
      case "pingentes":
        setTitle("pingentes");
        break;
      case "conjuntos":
        setTitle("conjuntos");
        break;
      case "tornozeleiras":
        setTitle("tornozeleiras");
        break;
      case "escapularios":
        setTitle("escapulários");
        break;
      case "noivas":
        setTitle("noivas");
        break;
      case "promocoes":
        setTitle("promoções");
        break;
      case "outros":
        setTitle("outros");
        break;
      default:
        return;
    }

    const collectionRef = collection(db, params.id);

    const getProducts = async (q) => {
      try {
        setLoading(true);
        const docSnapshot = await getDocs(q);
        if (docSnapshot.empty) {
          setProducts(null);
        } else {
          setProducts(docSnapshot);
        }
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (arrayTypes.includes(queryType)) {
      const q = query(collectionRef, where("categories", "array-contains", queryType));
      getProducts(q);
    } else if (queryType === "Maior preço") {
      const q = query(collectionRef, orderBy("price", "desc"), limit(limitRef));
      getProducts(q);
    } else if (queryType === "Menor preço") {
      const q = query(collectionRef, orderBy("price", "asc"), limit(limitRef));
      getProducts(q);
    } else if ("Todos") {
      getProducts(query(collectionRef, orderBy("name", "asc"), limit(limitRef)));
    } else {
      getProducts(query(collection, orderBy("name"), limit(limitRef)));
    }
  }, [collection, db, getDocs, orderBy, params.id, query, queryType, where, limit, limitRef, title]);

  const handleLimit = () => {
    let count = limitRef;
    setLimitRef(count + 4);
  };

  return (
    <>
      <Helmet>
        <meta property="og:url" content={`https://inessobra.com.br/catalogo${params.id}`} />
        <meta property="og:title" content="Inês Sobral - Catálogo" />
        <meta property="og:description" content={`Catálogo de ${title}`} />
        <meta property="og:site_name" content="Inês Sobral" />
        <title>{`Catálogo de ${title}`}</title>
      </Helmet>
      <section>
        <img src={`../assets/slides/home-slide-${params.id}.jpg`} className={styles.static_img} alt="Banner"></img>
        <div className={`${styles.produto} grid`}>
          <aside className={styles.banner}>
            <h1>Catálogo de {title}</h1>
          </aside>

          <ul className={styles.tip}>
            {title === "noivas" && (
              <li>
                <Noiva />
                <p>Alugamos e vendemos peças para noivas</p>
              </li>
            )}
            {title === "promoções" && (
              <>
                <li>
                  <Promo />
                  <div>
                    <p>Desconto não cumulativo</p>
                    <p className={styles.tip_regular}>
                      O desconto de primeira compra não é válido para itens em promoção.
                    </p>
                  </div>
                </li>
                <li>
                  <Watch />
                  <div>
                    <p>Promoção limitada</p>
                    <p className={styles.tip_regular}>A promoção permanecerá disponível enquanto houver estoque.</p>
                  </div>
                </li>
              </>
            )}
          </ul>
          <div className={styles.filter}>
            <div className={styles.select}>
              <p>Ordenar por:</p>
              <Filter options={tags[params["id"]]} setQueryType={setQueryType} />
            </div>

            <a href="https://wa.me/+5587996108283" className={styles.button_zap}>
              Faça seu pedido agora
              <Whats fill="white" width={"40px"} height={"40px"} />
            </a>
          </div>

          {!products ? (
            <h1 style={{ textAlign: "center", margin: "4rem 0" }} className={"show"}>
              Produtos não encontrados
            </h1>
          ) : (
            <ul className={styles.product_env}>
              {products.docs.map((doc) => {
                const data = doc.data();
                return (
                  <li key={doc.id} className={`${styles.product} show`}>
                    <a href="https://wa.me/+5587996108283" rel="noreferrer" target="_blank">
                      <img src={data.url} className={styles.model} alt={"Fotografia produto"}></img>
                      <div className={styles.specs}>
                        <p className={styles.specs_title}>{data.name}</p>
                        <p className={styles.specs_sub}>Cod: {data.pid}</p>
                        {title !== "promoções" ? (
                          <p className={styles.specs_sub}>
                            Preço: <span className={styles.style_span}>R$ {String(data.price).replace(".", ",")}</span>
                          </p>
                        ) : (
                          <p className={styles.specs_sub}>
                            De: <span className={styles.risco}>R$ {String(data.price).replace(".", ",")}</span>
                            <span className={styles.style_span}>
                              por: R$ {String(data.promo_price).replace(".", ",")}
                            </span>
                          </p>
                        )}
                        <p className={styles.specs_sub}>Estoque: {data.stock}</p>
                      </div>
                    </a>
                  </li>
                );
              })}
            </ul>
          )}
          {loading ? (
            <>
              <div style={{ maxWidth: "6rem", margin: "4rem auto" }}>
                <span className="loading"></span>
                <span className="loading"></span>
                <span className="loading"></span>
              </div>
            </>
          ) : null}
          <button className="submit_button center" onClick={handleLimit}>
            Ver mais
          </button>
        </div>
      </section>
    </>
  );
};

export default ProdutoSpec;
