import React from "react";
import styles from "./Slide.module.css";
import { Splide } from "@splidejs/splide";
import "../../../node_modules/@splidejs/splide/dist/css/splide.min.css";
import { Link } from "react-router-dom";
// import { AnchorContext } from "../../contexts/scrollContext";

const Slide = ({ path }) => {
  // const { handleAnchor } = React.useContext(AnchorContext);
  const slides = [
    {
      img: "home-slide-aneis",
      url: "/catalogo/aneis",
    },
    {
      img: "home-slide-brincos",
      url: "/catalogo/brincos",
    },
    {
      img: "home-slide-colares",
      url: "/catalogo/colares",
    },
    {
      img: "home-slide-escapularios",
      url: "/catalogo/escapularios",
    },
    {
      img: "home-slide-loja",
      url: "/sobre",
    },
    {
      img: "home-slide-outros",
      url: "/catalogo/outros",
    },
    {
      img: "home-slide-pingentes",
      url: "/catalogo/pingentes",
    },
    {
      img: "home-slide-promocoes",
      url: "/catalogo/promocoes",
    },
    {
      img: "home-slide-pulseiras",
      url: "/catalogo/pulseiras",
    },
    {
      img: "home-slide-tornozeleiras",
      url: "/catalogo/tornozeleiras",
    },
    {
      img: "home-slide-noivas",
      url: "/catalogo/noivas",
    },
  ];

  React.useEffect(() => {
    const config = {
      type: "loop",
      autoplay: true,
      breakpoints: {
        768: {
          height: "37rem",
        },
        666: {
          height: "32rem",
        },
        560: {
          height: "28rem",
        },
        480: {
          height: "24rem",
        },
        375: {
          height: "18rem",
        },
      },
    };
    const slide = new Splide(".splide", config);
    slide.mount();
  }, []);

  return (
    <section>
      <div className="splide">
        <div className="splide__track">
          <ul className="splide__list">
            {slides.map((slide) => {
              return (
                <li className="splide__slide" key={slide.url}>
                  <Link to={slide.url} className={styles.slide_img}>
                    <img
                      srcSet={`${path}/slides/${slide.img}.jpg 1000w, ${path}/slides/${slide.img}@2x.jpg 1920w`}
                      alt="Banner Produto"
                      className={styles.slide_img}
                    ></img>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Slide;
