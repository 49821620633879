import React from "react";
import styles from "./Form.module.css";
import Input from "./Input";
import useFirestoreDocs from "../../hooks/useFirestoreDocs";

const Form = () => {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [client, setClient] = React.useState({
    name: "",
    number: "",
    birth: "",
  });

  const { addFirestore } = useFirestoreDocs();

  const handleClient = ({ target }) => {
    setClient({ ...client, [target.id]: target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const r = await addFirestore("users", client);
      console.loog(r);
    } catch (error) {
      setError(true);
    } finally {
      setError(false);
      setLoading(false);
    }
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <Input
        type="text"
        id="name"
        name="name"
        placeholder="Nome"
        value={client.name}
        onChange={handleClient}
      />
      <Input
        type="text"
        id="number"
        name="number"
        placeholder="(Telefone, ex: (87) 9999-9999)"
        value={client.number}
        onChange={handleClient}
      />
      <Input
        type="text"
        id="birth"
        name="birth"
        placeholder="Data de nascimento ex: (dd/mm)"
        value={client.birth}
        onChange={handleClient}
      />
      {error ? (
        <p className="errorIn">Ocorreu um erro, tente novamente.</p>
      ) : null}
      <button className={styles.submit}>
        {loading ? "Cadastrando" : "Cadastrar"}
      </button>
    </form>
  );
};

export default Form;
