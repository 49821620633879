import React from "react";
import useFirestoreDocs from "../../../hooks/useFirestoreDocs";
import styles from "../Editor/Comp.module.css";

const Cadastro = () => {
  const [data, setData] = React.useState(null);
  const [limitRef, setLimitRef] = React.useState(10);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const { readFirestoreAll, deleteFirestoreDoc } = useFirestoreDocs();
  const [refresh, setRefresh] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const r = await readFirestoreAll("users", limitRef, ["name"]);
        setData(r);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [limitRef, readFirestoreAll, refresh]);

  const handleLimit = () => {
    setLimitRef(limitRef + 10);
  };

  const handleDelete = async () => {
    try {
      setError(false);
      setLoading(true);
      if (Array.isArray(data.docs)) {
        const arrayUsers = data.docs.map((doc) => doc.id);
        arrayUsers.forEach(async (doc) => {
          await deleteFirestoreDoc("users", doc);
          setData(null);
        });
        setRefresh(true);
      } else {
        handleDeleteDoc({ target: data.docs[0].id });
      }
    } catch (error) {
      console.log(error.message);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteDoc = async ({ target }) => {
    try {
      setError(false);
      setLoading(true);
      await deleteFirestoreDoc("users", target.value);
      setData(null);
      setRefresh(true);
    } catch (error) {
      setError(true);
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!data) {
    return (
      <h1 className="show" style={{ marginLeft: "7rem" }}>
        Nenhum cadastro
      </h1>
    );
  }
  return (
    <section className={`${styles.component} show`}>
      <h1>Cadastros</h1>
      {data.docs.map((doc) => {
        return (
          <li key={doc.id} className={styles.person_env}>
            <div className={styles.person}>
              <p className={styles.person_title}>{doc.data().name}</p>
              <span className={styles.separador}></span>
              <p>Tel: {doc.data().number}</p>
              <span className={styles.separador}></span>
              <p>Nasc: {doc.data().birth}</p>
              <button onClick={handleDeleteDoc} value={doc.id}>
                {loading ? "Deletando" : "Deletar"}
              </button>
            </div>
          </li>
        );
      })}
      <div className={styles.buttons_env}>
        <button className="submit_button" onClick={handleDelete}>
          {loading ? "Deletando" : "Deletar todos"}
        </button>
        <button className="submit_button" onClick={handleLimit}>
          Ver mais
        </button>
      </div>
      {error && (
        <p
          style={{ marginTop: "2rem", color: "red", fontWeight: "700" }}
          className="show"
        >
          Erro ao deletar, tente novamente.
        </p>
      )}
    </section>
  );
};

export default Cadastro;
