import React from "react";

const useNewsletter = () => {
  const [error, setError] = React.useState(null);

  const handleBlur = ({ target }) => {
    if (target.value.length === 0) {
      setError("Preencha os dados");
    } else {
      setError(null);
    }
  };

  return { error, setError, handleBlur };
};

export default useNewsletter;
