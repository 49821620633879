import { deleteDoc } from "firebase/firestore";
import React from "react";
import { useFirebase } from "./useFirebase";

const useFirestoreDocs = () => {
  const {
    db,
    collection,
    getDocs,
    getDoc,
    addDoc,
    query,
    limit,
    where,
    orderBy,
    doc,
    setDoc,
  } = useFirebase();

  const addFirestore = async (ref, fields) => {
    try {
      const collectionRef = collection(db, ref);
      await addDoc(collectionRef, fields);
    } catch (error) {
      console.log(error.message);
    }
  };

  const setFirestore = async (e, lookCollection, pageRef, fields) => {
    try {
      e.preventDefault();
      const docRef = doc(db, lookCollection, pageRef + "id");
      await setDoc(docRef, fields, { merge: true });
    } catch (error) {
      console.log(error.message);
    }
  };

  const setFirestoreDoc = async (collectionRef, id, fields) => {
    try {
      const docRef = doc(db, collectionRef, id);
      const r = await setDoc(docRef, fields);
      return r;
    } catch (error) {
      console.log(error.message);
    }
  };

  const readFirestoreAll = React.useCallback(
    async (ref, limitRef, orderType) => {
      try {
        const collectionRef = collection(db, ref);
        const q = query(collectionRef, orderBy(...orderType), limit(limitRef));
        const response = await getDocs(q);
        return response;
      } catch (error) {
        console.log(error.message);
      }
    },
    [collection, db, getDocs, limit, query, orderBy],
  );

  const readFirestoreDoc = React.useCallback(
    async (ref, id) => {
      try {
        const docRef = doc(db, ref, id);
        const snapShot = await getDoc(docRef);
        return snapShot;
      } catch (error) {
        console.log(error.message);
      }
    },
    [db, doc, getDoc],
  );

  const readFirestore = React.useCallback(
    async (collRef, whereRef, condition, pageRef) => {
      try {
        const collectionRef = collection(db, collRef);
        const customQuery = query(
          collectionRef,
          where(whereRef, condition, pageRef),
        );
        const querySnapshot = await getDocs(customQuery);
        return querySnapshot;
      } catch (error) {
        console.log(error.message);
      }
    },
    [collection, db, getDocs, query, where],
  );

  const deleteFirestoreDoc = async (collRef, id) => {
    try {
      const docRef = doc(db, collRef, id);
      const r = await deleteDoc(docRef);
      return r;
    } catch (error) {
      console.log(error.message);
    }
  };

  return {
    addFirestore,
    setFirestore,
    setFirestoreDoc,
    readFirestore,
    readFirestoreDoc,
    readFirestoreAll,
    deleteFirestoreDoc,
  };
};

export default useFirestoreDocs;
