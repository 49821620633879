import React from "react";
import styles from "./Exclusive.module.css";
const Exclusive = () => {
  return (
    <section className={styles.exclusive}>
      <h1>Peças exclusivas</h1>
      <p>Não trabalhamos com quantidade. Aqui, as peças não são repetidas.</p>
    </section>
  );
};

export default Exclusive;
