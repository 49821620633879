import React from "react";
import styles from "./Header.module.css";
import Logo from "../../svg/logo-v2.svg";
import Menu from "../Menu/Menu";
import { Link } from "react-router-dom";

const StandardHeader = () => {
  return (
    <header className={styles.header}>
      <div className="grid">
        <Link to="/" className={styles.logo}>
          <img src={Logo} alt="Ines Sobral"></img>
        </Link>
        <Menu />
      </div>
    </header>
  );
};

export default StandardHeader;
