import React from "react";
import styles from "./Dropdown.module.css";
import { Link } from "react-router-dom";
import { AnchorContext } from "../../contexts/scrollContext";

const Dropdown = ({ active, setActive }) => {
  const { handleAnchor } = React.useContext(AnchorContext);

  return (
    <ul
      className={
        active ? `${styles.menu_list} ${styles.open}` : `${styles.menu_list}`
      }
    >
      <li
        onClick={() => {
          setActive(!active);
        }}
      >
        <Link
          to={{
            pathname: "",
            hash: "catalogo",
          }}
          onClick={handleAnchor}
        >
          Catálogo de Produtos
        </Link>
      </li>
      <li
        onClick={() => {
          setActive(!active);
        }}
      >
        <Link to={{ pathname: "", hash: "discount" }} onClick={handleAnchor}>
          Ganhe 10% de desconto
        </Link>
      </li>
      <li
        onClick={() => {
          setActive(!active);
        }}
      >
        <a href="https://wa.me/+5587996108283" rel="noreferrer" target="_blank">
          Falar no WhatsApp
        </a>
      </li>
      <li
        onClick={() => {
          setActive(!active);
        }}
      >
        <a
          href="https://messenger.com/t/mariaines.espindola"
          rel="noreferrer"
          target="_blank"
        >
          Ir para Messenger
        </a>
      </li>
      <li
        onClick={() => {
          setActive(!active);
        }}
      >
        <a
          href="https://instagram.com/inessobralsemijoias"
          rel="noreferrer"
          target="_blank"
        >
          Ir para Instagram
        </a>
      </li>
      <li
        onClick={() => {
          setActive(!active);
        }}
      >
        <Link to={{ pathname: "", hash: "contato" }} onClick={handleAnchor}>
          Contato e endereço
        </Link>
      </li>
      <li
        onClick={() => {
          setActive(!active);
        }}
      >
        <Link to="sobre">Sobre</Link>
      </li>
    </ul>
  );
};

export default Dropdown;
